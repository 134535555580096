import './App.css';
import Navbar from "./navbar/Navbar";
import Home from "./home/index";
import {Test} from "./test-poc/Test";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
     <Navbar />
     <main className="main-content">
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/test" element={<Test />} />
       </Routes>
     </main>
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}
export default App;