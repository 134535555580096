import React from "react";
 
function Home(){
    return (
        <div>
            <center>
            <h1>
              Bienvenido!
            </h1>
            </center>
        </div>
    );
};
export default Home;