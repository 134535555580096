import React from "react";
import {AppSelect,AppSelectOption} from "../components/app-select/index";
import {AppInput} from "../components/app-input/index";
import "./test.css";
import { VscDiffAdded } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

if(localStorage.getItem('fields_v1') == null || localStorage.getItem('fields_v1') === "" ) { 
    const itemList = [];
    localStorage.setItem('fields_v1',JSON.stringify(itemList));
}

function TitleTest(props) {
    if (props.items.length == 0) {
        return <center>No hay items agregados</center>;
    }
    return <center>Campos de Prueba: {props.items.length}</center>;
}

function Test(){
    let parseItems = JSON.parse(localStorage.getItem('fields_v1'));
    const [addItem,setAddItem] = React.useState(parseItems); 
    const [inputField,setInputField] = React.useState('');
    const fieldType = [
        {id:1,value:'Texto'},
        {id:2,value:'Selección'},
        {id:3,value:'Checkbox'},
        {id:4,value:'Radio'},
        {id:5,value:'Area Texto'},
      ];
      

    return (
        <>
        <br /><br />
        <div className="form-test">
            <h3 className="custom_margin" >Hola, configura el campo y agregalo:</h3>
            <p className="custom_margin">
                <AppSelect fieldName="Tipo:">
                    {fieldType.map(item=>(
                        <AppSelectOption key={item.id} value={item.value}  />
                    ))}
                </AppSelect>
            </p>
            <p className="custom_margin">
                <strong className="app__input_description" >Nombre:</strong>
                <input
                    className="app__input"
                    type="text"
                    value={inputField}
                    placeholder="Ingrese Valor"
                    onChange={(e) => setInputField(e.target.value)}
                />
            </p>
            <button className="button__delete custom_margin" onClick={
                (event) =>{
                    localStorage.setItem("fields_v1",JSON.stringify([]));
                    setAddItem([]);
                }
            } >
              <VscChromeClose /> &nbsp; Eliminar Todos
            </button>
            <button className="button__add custom_margin" onClick={
                (event) =>{
                    console.log("click: "+event.target+"- "+inputField);
                    let items = JSON.parse(localStorage.getItem("fields_v1"));
                    items.push({"id":"if_"+items.length,"description":inputField});
                    localStorage.setItem("fields_v1",JSON.stringify(items));
                    console.log(items.length);
                    setAddItem(items);
                }
            } >
             <VscDiffAdded />&nbsp;&nbsp; Agregar 
            </button>
        </div>
        <div className="form-result">
            <TitleTest  items={addItem} />
            <br />
            {addItem.map(item=>(
               <AppInput key={item.id} fieldName={item.description} placeholder={item.description} />
            ))}
           
        </div>
        </>
    );
};
 
export {Test};