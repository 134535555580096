import React from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import logo from '../assets/logo.svg';

function Navbar () {
    return (
        <header className="header">
          <nav className="nav container">
            <NavLink to="/" className="nav__logo">
              Desoftpe
            </NavLink>
            <img src={logo} className="App-logo" alt="logo" />
            <div
              className={"nav__menu"}
              id="nav-menu"
            >
              <ul className="nav__list">
                <li className="nav__item">
                  <NavLink to="/" className="nav__link">
                    Inicio
                  </NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/Test" className="nav__link">
                    Test
                  </NavLink>
                </li>
              </ul>
              <div className="nav__close" id="nav-close">
                <IoClose />
              </div>
            </div>
            <div className="nav__toggle" id="nav-toggle">
              <IoMenu />
            </div>
          </nav>
        </header>
      );
};
export default Navbar;