import React from "react";
import "./app-select.css";
 
function AppSelectOption (props) {
    return (
        <option id="{props.id}">{props.value}</option>
    );
}

function AppSelect(props){
    return (
        <>
            <strong className="app__select_description" >{props.fieldName}</strong>
            <select className="app__select">
                {props.children}
            </select>
        </>
    );
};
export  {AppSelect,AppSelectOption};