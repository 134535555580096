import React from "react";
import "./app-input.css";
 
function AppInput(props){
    return (
        <>
            <strong className="app__input_description" >{props.fieldName}</strong>
            <input key={props.id} type="text" className="app__input" placeholder={props.placeholder} />
            <br /><br />
        </>
    );
};
export {AppInput};